$(document).ready(function(){
  $('#check-user-password').on('click', function(){
    const email = $("input[name='user[email]']").val();
    const password = $("input[name='user[password]']").val();

    Shared.callAjax(Routes.owner_check_user_password_token_path(), {'email': email, 'password': password})
    .done(function(data){
      if (data.is_receive_otp === 'true') return $('#btn-show-otp-token').trigger('click');

      $('#check-user-password').parents('form').submit();
    }).fail(function(){
      Popup.alert('メールアドレスまたはパスワードが違います。');
    });
  })

  $('#btn-sign-in-token').on('click', function(){
    const token = $("input[name='token']").val();
    const email = $("input[name='user[email]']").val();
    const password = $("input[name='user[password]']").val();

    Shared.callAjax(Routes.owner_verify_user_otp_path(), { token: token, email: email, password: password })
    .done(function(){
      $('#check-user-password').parents('form').submit();
    }).fail(function(){
      Popup.alert('このコードは無効です。<br>「再発行する」をクリックしてコードを再発行してください。');
    })
  })

  $('#send-mail-token').on('click', function(){
    const email = $("input[name='user[email]']").val();
    const password = $("input[name='user[password]']").val();

    Shared.callAjax(Routes.owner_check_user_password_token_path(), {'email': email, 'password': password})
    .done(function(data){
      if (data.is_receive_otp === 'true') {
        $('.send-mail-token').removeClass('d-none');
        $('.input-otp-token').addClass('mt-3');
        $('#btn-check-token-first-auth').text('次へ');
      }
    }).fail(function(){
      Popup.alert('メールアドレスまたはパスワードが違います。');
    });
  })
})
